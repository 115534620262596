export const COLORS = {
    WHITE: "#fff",
    WHITEARRAY: [255, 255, 255, 1],
    GREY: "#181818",
    GREYARRAY: [24, 24, 24, 1],
    YELLOW: "#e0ff00",
    YELLOWARRAY: [224, 255, 0, 1],
    PINK: "#f59aa9",
    ORANGE: "#ff5c01",
    PLACEHOLDERGREY: "#a1a1a1",
    PLACEHOLDERGREYARRAY: [161, 161, 161, 1],
}

const drawerWidth = 240;

export function commonStyles(theme) {
    return {
        root: {
            display: "flex",
            margin: "0 auto",
            maxWidth: "90%",
            minHeight: "100vh",
            backgroundColor: '#000',
            fontFamily: "futura-lt-w01-book,sans-serif",
        },
        content: {
            margin: "40px auto",
            maxWidth: "90%",
        },

        section: {
            display: 'flex',
            flexDirection: 'column',

            // NOTE: This breaks the aspect ratio box
            // alignItems: 'center',

            overflow: 'hidden',
        },

        copyNormal: {
            fontFamily: '"Roboto Mono", Arial, Helvetica, sans-serif', 
            color: '#e6e6e6', 
            fontSize: 16, 
            fontWeight: '400',
        },

        link: {
            color: COLORS.PLACEHOLDERGREY,
            '&:link': {
                color: COLORS.PLACEHOLDERGREY,
            },
            '&:visited': {
                color: COLORS.PLACEHOLDERGREY,
            },
            '&:hover': {
                color: COLORS.PLACEHOLDERGREY,
            },
            '&:active': {
                color: COLORS.PLACEHOLDERGREY,
            },
        },
        yellow: {
            color: COLORS.YELLOW,
            '&:link': {
                color: COLORS.YELLOW,
            },
            '&:visited': {
                color: COLORS.YELLOW,
            },
            '&:hover': {
                color: COLORS.YELLOW,
            },
            '&:active': {
                color: COLORS.YELLOW,
            },
        },

        drawer: {
          [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
          },
          [theme.breakpoints.between("sm", "xl")]: {
            width: 0
          }
        },
        appBar: {
          backgroundColor: "#fff",
          [theme.breakpoints.up("sm")]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
          },
          [theme.breakpoints.between("sm", "xl")]: {}
        },
        headerLogo: {
          display: "flex",
          maxWidth: 285,
          maxHeight: 52,
          marginRight: 10,
          [theme.breakpoints.down("xs")]: {
            maxWidth: 285 * 0.8,
            maxHeight: 52 * 0.8
          }
        },
        menuButton: {
          marginRight: 20,
          backgroundColor: "rgb(244, 117, 33)",
          "&:hover": {
            backgroundColor: "rgb(244, 117, 33)"
          },
          [theme.breakpoints.up("sm")]: {
            display: "none"
          }
        },
        topNav: {
          display: "flex",
          flexGrow: 100,
          alignItems: "stretch",
          [theme.breakpoints.down("xs")]: {
            display: "none"
          }
        },
        navButton: {
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          color: "#666",
          // display: 'flex',
          flex: 1,
          lineHeight: 1.1,
          // height: '100%',
          alignSelf: "center",
          textAlign: "center",
          "&:hover": {
            color: "#333"
            //   color: "#fff",
            //   background: "rgba(244, 117, 33, 0.5)"
          }
        },
        navButtonSelected: {
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          color: "#666",
          flex: 1
        },
        businessLoginLink: {
            margin: 'auto'
        },
        businessLoginButton: {
          backgroundColor: "rgb(244, 117, 33)",
          border: "none",
          color: "#fff",
          padding: "0px 20px",
          textAlign: "center",
          textDecoration: "none",
          display: "inline-block",
          lineHeight: 1.1,
          // margin: "auto", // "4px 2px",
          height: 40,
          cursor: "pointer",
          borderRadius: "20px",
          [theme.breakpoints.down("xs")]: {
              fontSize: 12
            }  
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
          [theme.breakpoints.up("sm")]: {
            width: drawerWidth
          },
          [theme.breakpoints.between("sm", "xl")]: {
            display: "none"
          }
        },
        // content: {
        //   flexGrow: 1
        //   // padding: theme.spacing.unit * 3
        // },
        // section: {
        //   display: "flex",
        //   flexDirection: "column",
        //   alignItems: "center",
        //   // marginBottom: 40
        //   margin: "auto",
        //   maxWidth: 978
        // },
        sectionMainTitle: {
          fontFamily: 'Futura, "Trebuchet MS", Arial, sans-serif',
          color: "#fff",
          [theme.breakpoints.down("xs")]: {
            fontSize: 42
          }
        },
        sectionHeader: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
          // fillOpacity: "0.6",
          backgroundColor: "rgba(244, 117, 33, 1)",
          width: "100%"
        },
        sectionHeaderTitle: {
          fontFamily: 'Futura, "Trebuchet MS", Arial, sans-serif',
          color: "#fff",
          fontWeight: "100",
          [theme.breakpoints.down("xs")]: {
            fontSize: 40
          }
        },
        sectionHeaderSubtitle: {
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          color: "#fff",
          fontStyle: "italic",
          lineHeight: 1.2
        },
        sectionContentList: {
          display: "flex",
          flexDirection: "column",
          // alignItems: 'center',
          maxWidth: 500,
          margin: 40,
          [theme.breakpoints.down("xs")]: {
            margin: 10
          }
          // alignSelf: 'center'
        },
        sectionContentListItem: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: 10
        },
        sectionContentListItemText: {
          fontFamily: 'Futura, "Trebuchet MS", Arial, sans-serif',
          fontWeight: "100",
          marginLeft: 20
        },
        sectionVideo: {
          display: "flex",
          width: "100%",
          [theme.breakpoints.down("xs")]: {
            display: "block"
          }
        },
        divider: {
          marginTop: 20,
          marginBottom: 20,
          border: "none",
          borderTop: "2px dashed rgb(244, 117, 33)",
          color: "#fff",
          backgroundColor: "#fff",
          height: "1px"
        },
        teamMemberGroup: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          [theme.breakpoints.down("xs")]: {
            display: "block"
          }
        },
        teamMember: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          marginLeft: 10,
          marginRight: 10,
          [theme.breakpoints.down("xs")]: {
            paddingBottom: 25
          }
        },
      
        badgeRoot: {
          display: 'inline'
        },
        badgeBadge: {
          width: 'initial',
          top: -22,
          right: -22,
          padding: 8,
          borderRadius: 11
        },
      
      
        footer: {
          display: "flex",
          marginTop: 40,
          height: 60,
          borderTop: "2px solid #333"
          // paddingBottom: 400
        },
        footerContent: {
          display: "flex",
          margin: "auto",
          width: "100%",
          maxWidth: 978,
          justifyContent: "space-between",
          padding: "10px 20px",
          lineHeight: 1.1
        }
    }
}
