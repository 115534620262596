import firebase from 'firebase/app';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyAHvMU7qeyBXKI4igu3_McFx6Mp3GpfCC4",
    authDomain: "streetfc-a99d6.firebaseapp.com",
    databaseURL: "https://streetfc-a99d6.firebaseio.com",
    projectId: "streetfc-a99d6",
    storageBucket: "streetfc-a99d6.appspot.com",
    messagingSenderId: "342725824986",
    appId: "1:342725824986:web:0bb7d5d5906ffea9"
};
firebase.initializeApp(config);

export default firebase;
