import axios from 'axios'
// import firebase from 'firebase'

class User {

    static view = async (id, fuid) => {
        try {
            const path = id ? `id/${id}` : `fuid/${fuid}`
            const response = await axios.get(
                `${process.env.REACT_APP_API}/api/v1/users/view/${path}`
            )
            
            // console.log('response', response)
            return response.data.user
        } catch (error) {
            console.warn(error)
        }
    }

    static saveField = async ({ user, field, value }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/v1/users/saveField`,
                {
                    user,
                    field,
                    value,   
                }
            )
            
            // console.log('response', response)
            return response.data
        } catch (error) {
            console.warn(error)
        }
    }

    static customer = async (id) => {
        const response = await axios.get(
            `${process.env.REACT_APP_API}/api/v1/users/loadCustomer?id=${id}`
        ).catch(error => { throw error })
        
        if (response.data.success) {
            return response.data
        } else {
            throw Error(response.data.error)
        }
    }

    static stats = async (params) => {
        // const uid = firebase.auth().currentUser.uid;
        // alert(params)
        // console.log(`params`)
        // console.log(params)
        const response = await axios.post(
            `${process.env.REACT_APP_API}/api/v1/users/stats`, 
            params
        ).catch(error => { throw error })
        
        if (response.data.success) {
            return response.data.users
        } else {
            throw Error(response.data.error)
        }
    }

    static async savePhoto(photoUrl) {
    }

    static fullName(user) {
        return `${user.firstName} ${user.lastName}`
    }
    
}

export default User
