import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Footer from "../common/Footer"
import { commonStyles } from '../../constants'
import Logo from '../../images/logo.jpg'
import AppStore from '../../images/appstore.png'
import GooglePlay from '../../images/googleplay.png'

const Invite = ({ classes }) => {
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.section}>
            <img 
                className={classes.sfcLogo}
                src={Logo} 
                alt="Street FC" 
                title="Street FC" 
            />
            <p className={classes.copyNormal} style={{ textAlign: 'center', }}>Download the Street FC app now!</p>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                <a href="https://streetfc.app.link/YZun4nzATjb"><img style={{ margin: 10 }} src={AppStore} alt="App Store" width="128" height="41" /></a>
                <a href="https://streetfc.app.link/s0XZ8W7ATjb"><img style={{ margin: 10 }} src={GooglePlay} alt="Google Play" width="128" height="41" /></a>
            </div>
          </div>
        <Footer />
      </main>
    </div>
  );
}
  
const styles = theme => ({
    ...commonStyles(theme),
    sfcLogo: {
        // margin: 40, 
        // width: '100%',
        // width: '50%',
        maxWidth: '90%',
        borderRadius: 25,
        margin: 'auto',
    }
});

export default withStyles(styles, { withTheme: true })(Invite);
