
class Country {
    // code: string;
    // name: string;
    // phone: string;

    constructor(code, name, phone) {
        this.code = code;
        this.name = name;
        this.phone = phone.replace("+", "");
    }

    // https://dev.to/jorik/country-code-to-flag-emoji-a21
    getFlagEmoji = () => {
        const codePoints = this.code
            .toUpperCase()
            .split('')
            .map((char) => 127397 + char.charCodeAt(0));
        return String.fromCodePoint(...codePoints);
    }

    // // https://stan.sh/posts/emoji-js-snippet
    // private flagEmoji(country_code) {
    //     var OFFSET = 127397;
    //     var cc = this.code.toUpperCase();
    //     function _toConsumableArray(arr) {
    //         if (Array.isArray(arr)) {
    //             for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
    //                 arr2[i] = arr[i];
    //             }
    //             return arr2;
    //         } else {
    //             return Array.from(arr);
    //         }
    //     }

    //     return /^[A-Z]{2}$/.test(cc) ? String.fromCodePoint.apply(String, _toConsumableArray([].concat(_toConsumableArray(cc)).map(function (c) {
    //         return c.charCodeAt() + OFFSET;
    //     }))) : null;
    // }

    longLabelMap = () => `${this.getFlagEmoji()} +${this.phone} ${this.name}`;
    shortLabelMap = () => `${this.getFlagEmoji()} +${this.phone}`;

    // longLabelMap = () => `${this.flagEmoji()} +${this.phone} ${this.name}`;
    // shortLabelMap = () => `${this.flagEmoji()} +${this.phone}`;
}

export default Country
