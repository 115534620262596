import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

// https://menubar.io/creating-a-material-ui-button-with-spinner-that-reflects-loading-state
const buttonStyles = {
    spinner: {
        marginLeft: 5
    }
};

const SpinnerAdornment = withStyles(buttonStyles)(props => (
  <CircularProgress className={props.classes.spinner} size={20} />
));

export default props => {
    const { children, loading, ...rest } = props;
    return (
        <Button {...rest}>
            {children}
            {loading && <SpinnerAdornment {...rest} />}
        </Button>
    );
};
