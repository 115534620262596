import React, { Component } from "react";
import {
    Redirect,
    BrowserRouter as Router,
    Route,
    Switch,
    useHistory,
    withRouter
} from "react-router-dom";

import Invite from "./components/Invite";
import {
    Discord,
} from "./components/Discord"
import {
    Liability,
    Privacy,
    Rules,
    Terms,
} from "./components/Legal"
import Game from "./components/Game";

const Wrap = (props) => {
  // console.log('props', JSON.stringify(props, null, 4))
  return <iframe
      style={{ width: "100vw", height: "100vh", border: '0' }}
      title="StreetFC.city"
      // src="https://raspberry-kazoo-9p6l.squarespace.com"
      src={props.url}
  />
}

const Forward = (props) => {
    // console.log('props', JSON.stringify(props, null, 4));
    return <iframe
        style={{ width: "100vw", height: "100vh", border: '0' }}
        title="StreetFC.city"
        // src="https://raspberry-kazoo-9p6l.squarespace.com"
        src={`https://streetfc.city${props.match.url}`}
        onLoadStart={ (e) => {
            console.log('e', JSON.stringify(e, null, 4))
        }}
        onChange={ (e) => {
            console.log('e', JSON.stringify(e, null, 4))
        } }
    />
}

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/invite" component={Invite} />
                <Route path="/game/view/:id?" render={ ({ match }) => {
                    // const scope = match.params.scope || "public";
                    // const id = match.params.id ? `/${match.params.id}`: '';
                    // const dataUrl = `${serverUrl}/api/v1/dashboard/sections/${scope}${id}`;
                    // return <Game serverUrl={ serverUrl } dataUrl={ dataUrl } />
                    return <Game />
                } } />
                <Route path="/game/:id?" render={ ({ match }) => {
                    // const scope = match.params.scope || "public";
                    // const id = match.params.id ? `/${match.params.id}`: '';
                    // const dataUrl = `${serverUrl}/api/v1/dashboard/sections/${scope}${id}`;
                    // return <Game serverUrl={ serverUrl } dataUrl={ dataUrl } />
                    return <Game />
                } } />
                <Route path="/liability" component={Liability} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/terms" component={Terms} />
                <Route path="/rules" component={Rules} />
                <Route path="/discord/id/:userId" component={Discord} />
                <Route
                    path='/2024'
                    component={() => {
                        window.location.href = 'https://www.canva.com/design/DAF7d4VLfsE/HT6hh2dA7mhowahoACx8iw/view?utm_content=DAF7d4VLfsE&utm_campaign=designshare&utm_medium=link&utm_source=editor';
                        return null;
                    }}
                />
                <Route
                    path='/deck'
                    component={() => {
                        window.location.href = 'https://www.canva.com/design/DAF9SdYe_Vc/KSWvmpm1LOQPVTOSzMUvtA/view?utm_content=DAF9SdYe_Vc&utm_campaign=designshare&utm_medium=link&utm_source=editor';
                        return null;
                    }}
                />
                <Route
                    path='/reebok'
                    component={() => {
                        window.location.href = 'https://streetfc.notion.site/Season-4-Join-Our-Supporters-Club-9239dc9148ca42b68a07400041a1fb4d';
                        return null;
                    }}
                />
                <Route
                    path='/invest'
                    component={() => {
                        window.location.href = 'https://www.canva.com/design/DAGA0BwPMo8/2jlrS2msX32VLbaLYUDDUQ/view?utm_content=DAGA0BwPMo8&utm_campaign=designshare&utm_medium=link&utm_source=editor';
                        return null;
                    }}
                />
                <Route
                    path='/download'
                    render={ () => {
                        window.location.href = 'https://streetfc.app.link/download';
                        return null;
                    }}
                />
                <Route
                    path='/sacramento'
                    render={ () => {
                        window.location.href = 'https://form.typeform.com/to/vOcRW0ux';
                        return null;
                    }}
                />
                <Route
                    path="/*"
                    component={Forward}
                />
          </Switch>
        </Router>
    );
}

export default withRouter(App);
