import 'dotenv/config'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { unregister } from './registerServiceWorker';

import "./assets/css/nucleo-icons.css"

ReactDOM.render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
), document.getElementById('root'))

// registerServiceWorker()
unregister();
